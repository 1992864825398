import moment from 'moment';

const checkboxAnchor = { anchorType: 'checkbox' };

const headerMap = {
  admin: {
    'community-management': [checkboxAnchor, '編號', '社區標籤', '社區名稱', '地址', '開通狀態', '合約狀態', '公告', '總覽', '編輯', '門牌', '切換帳號'],
    'management/service-account': [checkboxAnchor, '帳號', '名稱', '權限', '編輯']
  },
  serviceAdmin: {
    'vender/vender': [checkboxAnchor, '排序', '廠商標籤', '公司名稱', '統編', '服務分類', '合作/合約狀態', '其他資訊', '編輯'],
    'vender/quality-tag': [checkboxAnchor, '排序', '顯示', '標籤名稱', '編輯'],
    'service/service-classification': [checkboxAnchor, '分類ID', '排序', '分類名稱', '服務方案', '隱藏設定', '縣市/地區', '編輯'],
    'service/service-classification/advanced-filter-item': [checkboxAnchor, '排序', '進階篩選項目名稱', '編輯'],
    'vender/account': [checkboxAnchor, '帳號', '使用者名稱', '建立日期', '寄送新密碼', '編輯'],
    'vender/vender-staff': [checkboxAnchor, '名稱', '角色', '寄送新密碼', '編輯'],
    'service/service': [checkboxAnchor, '方案ID', '排序', '分類', '服務方案名稱', '隱藏設定', '編輯'],
    'order/reservation': [],
    'order/unordered-reservation': [],
    'order/reservation-quotation': ['編號', '總價', '文件', '發布給客戶確認', '客戶確認狀態', '內部備註', '編輯', '轉介費扣除項'],
    'order/customer': ['會員ID', '會員名稱', '註冊電話', 'email', '地址', '一般訂單完成數', '一般訂單總數', '工程訂單完成數', '工程訂單總數', '優惠券', '註冊時間(來源)', '信義房屋客戶', '信義同仁', '編輯'],
    'order/customer-group': [checkboxAnchor, '群組ID', '群組名稱', '會員數量', '下載', '編輯'],
    'marketing/coupon-event': [checkboxAnchor, '活動ID', '活動主題', '優惠名稱', '活動期間', '建立日期', '總數', '異業合作名稱', '信義用', '編輯'],
    'marketing/coupon': [checkboxAnchor, '優惠碼', '活動主題', '優惠名稱', '建立日期', '使用次數', '使用資訊', '優惠資訊'],
    'marketing/banner': [checkboxAnchor, '排序', '顯示', '活動名稱', '顯示通路', '版位', '活動期間', '區域限制', '編輯'],
    'marketing/ta3-banner': [checkboxAnchor, '排序', '顯示', '活動名稱', '活動期間', '編輯'],
    'marketing/notification': ['停止', '發送時間', '發送類型', '通知方式', '通知內容', '發送數量', '發送人', '內部備註', '發送結果'],
    'content/layout': [checkboxAnchor, '顯示', '頁面名稱', '連結', '更新時間', '更新人員', '編輯'],
    'content/article': [checkboxAnchor, '連結', '出版', '預覽', '文章類別', '文章標題', '上架區間', '更新日期', '建立時間', '閱覽數(新/舊)', '編輯'],
    'content/article-classification': [checkboxAnchor, '排序', '顯示', '分類名稱', '分類關鍵字(排序前五)', '編輯'],
    'content/keyword': [checkboxAnchor, '排序', '顯示', '關鍵字名稱', '文章閱覽數', '網址辨識名稱', '編輯'],
    'content/album': [checkboxAnchor, '排序', '顯示', '封面', '相簿名稱', '關鍵字(排序前五)', '相片數', '相片點閱數', '更新日期', '編輯'],
    'content/photo-tag': [checkboxAnchor, '排序', '標籤名稱', '網址辨識名稱', '編輯'],
    'content/photo': [checkboxAnchor, '排序', '相片圖', '相片名稱', '相片說明', '顯示', '相片分類標籤', '封面', '閱覽數(新/舊)', '編輯'],
    'content/layout-module': [checkboxAnchor, '模組ID', '顯示', '模組名稱', '模組類型', '使用頁面', '更新時間', '更新人員', '編輯'],
    'content/catalog': [checkboxAnchor, '服務選單顯示', '廠商列表搜尋顯示', '排序', '目錄名稱', '更新時間', '更新人員', '編輯'],
    'bill/bill': [checkboxAnchor, '廠商名稱', '單據類型', '單據名稱', '編號', '金額', '單據狀態', '明細', '下載', '通知'],
    'bill/account': ['廠商標籤', '公司名稱', { label: '虛擬帳號', tip: '虛擬帳號規則為924974+廠商統編號碼，例如：92497412345678，故系統上每筆廠商資料統編務必保持唯一。' }, { label: '帳戶金額', tip: '帳戶金額係為廠商匯款至專屬繳款虛擬帳號，系統定期處理匯款資訊，進行廠商帳單繳款核銷後剩餘的金額，剩餘金額可用於之後帳單繳款核銷時使用。' }, '交易明細'],
    'bill/account-transaction-detail': ['公司名稱', '交易日期', '交易內容', '交易金額', '交易前金額', '交易後金額']
  },
  vender: {
    'order/reservation': [],
    'order/unordered-reservation': [],
    'order/reservation-quotation': ['編號', '總價', '文件', '發布給客戶確認', '客戶確認狀態', '內部備註', '編輯', '轉介費扣除項'],
    'vender-staff': [checkboxAnchor, '名稱', '角色', '寄送新密碼', '編輯'],
    'marketing/notification': ['停止', '發送時間', '發送類型', '通知方式', '通知內容', '發送數量', '發送人', '內部備註', '發送結果'],
    'bill/bill': ['廠商名稱', '單據類型', '單據名稱', '編號', '金額', '單據狀態', '明細', '下載']
  }
};

const toolbarMap = {
  admin: {
    'community-management': {
      pagination: true,
      printBtn: { enable: false },
      removeBtn: { enable: true },
      addBtn: { enable: true, label: '新增', size: 'md' }
    },
    'management/service-account': {
      pagination: true,
      printBtn: { enable: false },
      removeBtn: { enable: true },
      addBtn: { enable: true, label: '新增', size: 'md' }
    }
  },
  serviceAdmin: {
    'vender/vender': {
      pagination: true,
      printBtn: { enable: false },
      removeBtn: { enable: true },
      addBtn: { enable: true, label: '新增', size: 'md' }
    },
    'vender/quality-tag': {
      pagination: true,
      printBtn: { enable: false },
      removeBtn: { enable: true },
      addBtn: { enable: true, label: '新增', size: 'md' }
    },
    'service/service-classification': {
      pagination: true,
      printBtn: { enable: false },
      removeBtn: { enable: true },
      addBtn: { enable: true, label: '新增', size: 'md' }
    },
    'service/service-classification/advanced-filter-item': {
      pagination: true,
      printBtn: { enable: false },
      removeBtn: { enable: true },
      addBtn: { enable: true, label: '新增', size: 'md' }
    },
    'vender/account': {
      pagination: true,
      printBtn: { enable: false },
      removeBtn: { enable: true },
      addBtn: { enable: true, label: '新增', size: 'md' }
    },
    'vender/vender-staff': {
      pagination: true,
      printBtn: { enable: false },
      removeBtn: { enable: true },
      addBtn: { enable: true, label: '新增', size: 'md' }
    },
    'service/service': {
      pagination: true,
      printBtn: { enable: false },
      removeBtn: { enable: true },
      addBtn: { enable: true, label: '新增', size: 'md' }
    },
    'order/reservation': {
      pagination: true,
      printBtn: { enable: false },
      removeBtn: { enable: false },
      addBtn: { enable: false, label: '新增', size: 'md' }
    },
    'order/reservation-quotation': {
      pagination: true,
      printBtn: { enable: false },
      removeBtn: { enable: false },
      addBtn: { enable: true, label: '新增', size: 'md' }
    },
    'order/unordered-reservation': {
      pagination: true,
      printBtn: { enable: false },
      removeBtn: { enable: false },
      addBtn: { enable: false, label: '新增', size: 'md' }
    },
    'order/customer': {
      pagination: true,
      printBtn: { enable: false },
      removeBtn: { enable: false },
      addBtn: { enable: false, label: '新增', size: 'md' }
    },
    'order/customer-group': {
      pagination: true,
      printBtn: { enable: false },
      removeBtn: { enable: true },
      addBtn: { enable: true, label: '新增', size: 'md' }
    },
    'marketing/coupon-event': {
      pagination: true,
      printBtn: { enable: false },
      removeBtn: { enable: false },
      addBtn: { enable: true, label: '新增', size: 'md' }
    },
    'marketing/coupon': {
      pagination: true,
      printBtn: { enable: false },
      removeBtn: { enable: true },
      addBtn: { enable: true, label: '新增', size: 'md' }
    },
    'marketing/banner': {
      pagination: true,
      printBtn: { enable: false },
      removeBtn: { enable: true },
      addBtn: { enable: true, label: '新增', size: 'md' }
    },
    'marketing/ta3-banner': {
      pagination: true,
      printBtn: { enable: false },
      removeBtn: { enable: true },
      addBtn: { enable: true, label: '新增', size: 'md' }
    },
    'marketing/notification': {
      pagination: true,
      printBtn: { enable: false },
      removeBtn: { enable: false },
      addBtn: { enable: true, label: '新增', size: 'md' }
    },
    'content/layout-module': {
      pagination: true,
      printBtn: { enable: false },
      removeBtn: { enable: true },
      addBtn: { enable: false, label: '新增', size: 'md' }
    },
    'content/catalog': {
      pagination: true,
      printBtn: { enable: false },
      removeBtn: { enable: true },
      addBtn: { enable: true, label: '新增', size: 'md' }
    },
    'content/layout': {
      pagination: true,
      printBtn: { enable: false },
      removeBtn: { enable: true },
      addBtn: { enable: true, label: '新增', size: 'md' }
    },
    'content/article': {
      pagination: true,
      printBtn: { enable: false },
      removeBtn: { enable: true },
      addBtn: { enable: true, label: '新增', size: 'md' }
    },
    'content/article-classification': {
      pagination: true,
      printBtn: { enable: false },
      removeBtn: { enable: true },
      addBtn: { enable: true, label: '新增', size: 'md' }
    },
    'content/keyword': {
      pagination: true,
      printBtn: { enable: false },
      removeBtn: { enable: true },
      addBtn: { enable: true, label: '新增', size: 'md' }
    },
    'content/album': {
      pagination: true,
      printBtn: { enable: false },
      removeBtn: { enable: true },
      addBtn: { enable: true, label: '新增', size: 'md' }
    },
    'content/photo-tag': {
      pagination: true,
      printBtn: { enable: false },
      removeBtn: { enable: true },
      addBtn: { enable: true, label: '新增', size: 'md' }
    },
    'content/photo': {
      pagination: true,
      printBtn: { enable: false },
      removeBtn: { enable: true },
      addBtn: { enable: false, label: '新增', size: 'md' }
    },
    'bill/bill': {
      pagination: true,
      printBtn: { enable: false },
      removeBtn: { enable: false },
      addBtn: { enable: false, label: '新增', size: 'md' }
    },
    'bill/account': {
      pagination: true,
      printBtn: { enable: false },
      removeBtn: { enable: false },
      addBtn: { enable: false, label: '新增', size: 'md' }
    },
    'bill/account-transaction-detail': {
      pagination: true,
      printBtn: { enable: false },
      removeBtn: { enable: false },
      addBtn: { enable: false, label: '新增', size: 'md' }
    }
  },
  vender: {
    'order/reservation': {
      pagination: true,
      printBtn: { enable: false },
      removeBtn: { enable: false },
      addBtn: { enable: false, label: '新增', size: 'md' },
      navigationBtn: { enable: false, label: '建立自建單', size: 'md' }
    },
    'order/unordered-reservation': {
      pagination: true,
      printBtn: { enable: false },
      removeBtn: { enable: false },
      addBtn: { enable: false, label: '新增', size: 'md' }
    },
    'order/reservation-quotation': {
      pagination: true,
      printBtn: { enable: false },
      removeBtn: { enable: false },
      addBtn: { enable: true, label: '新增', size: 'md' }
    },
    'vender-staff': {
      pagination: true,
      printBtn: { enable: false },
      removeBtn: { enable: true },
      addBtn: { enable: true, label: '新增', size: 'md' }
    },
    'marketing/notification': {
      pagination: true,
      printBtn: { enable: false },
      removeBtn: { enable: true },
      addBtn: { enable: true, label: '新增', size: 'md' }
    },
    'bill/bill': {
      pagination: true,
      printBtn: { enable: false },
      removeBtn: { enable: false },
      addBtn: { enable: false, label: '新增', size: 'md' }
    }
  }
};

const searchbarMap = {
  admin: {
    'community-management': { enable: true, showSearchBtn: true, textInput: { searchOptions: true, placeholder: '請輸入社區名稱' } },
    'management/service-account': { enable: true, showSearchBtn: true, textInput: { searchOptions: true, placeholder: '請輸入名稱' } }
  },
  serviceAdmin: {
    'vender/vender': { enable: true, showSearchBtn: true, textInput: { searchOptions: true, placeholder: '請輸入公司名稱' } },
    'vender/quality-tag': { enable: true, showSearchBtn: true, textInput: { searchOptions: true, placeholder: '請輸入廠商特色標籤名稱' } },
    'service/service-classification': { enable: true, showSearchBtn: true, textInput: { searchOptions: true, placeholder: '請輸入分類名稱' } },
    'service/service-classification/advanced-filter-item': { enable: false, showSearchBtn: true, textInput: { searchOptions: true, placeholder: '請輸入分類名稱' } },
    'vender/account': { enable: false, showSearchBtn: true, textInput: { searchOptions: true, placeholder: '請輸入名稱' } },
    'vender/vender-staff': { enable: true, showSearchBtn: true, textInput: { searchOptions: true, placeholder: '請輸入服務員名稱' } },
    'service/service': { enable: true, showSearchBtn: true, textInput: { searchOptions: true, placeholder: '請輸入服務方案名稱' } },
    'order/reservation': { enable: true, showSearchBtn: true, textInput: { searchOptions: true, placeholder: '請輸入訂單編號' } },
    'order/reservation-quotation': { enable: false, showSearchBtn: false, textInput: { searchOptions: true, placeholder: '' } },
    'order/unordered-reservation': { enable: true, showSearchBtn: true, textInput: { searchOptions: true, placeholder: '請輸入訂單編號' } },
    'order/customer': { enable: true, showSearchBtn: true, textInput: { searchOptions: true, placeholder: '請輸入註冊電話' } },
    'order/customer-group': { enable: true, showSearchBtn: true, textInput: { searchOptions: true, placeholder: '請輸入群組名稱' } },
    'marketing/coupon-event': { enable: true, showSearchBtn: true, textInput: { searchOptions: true, placeholder: '查詢活動主題' } },
    'marketing/coupon': { enable: true, showSearchBtn: true, textInput: { searchOptions: true, placeholder: '請輸入優惠碼' } },
    'marketing/banner': { enable: true, showSearchBtn: true, textInput: { searchOptions: true, placeholder: '請輸入活動名稱' } },
    'marketing/ta3-banner': { enable: false, showSearchBtn: true, textInput: { searchOptions: true, placeholder: '請輸入活動名稱' } },
    'marketing/notification': { enable: true, showSearchBtn: true, textInput: { searchOptions: true, placeholder: '查詢用戶手機' } },
    'content/layout-module': { enable: true, showSearchBtn: true, textInput: { searchOptions: true, placeholder: '查詢' } },
    'content/layout': { enable: true, showSearchBtn: true, textInput: { searchOptions: true, placeholder: '查詢' } },
    'content/article': { enable: true, showSearchBtn: true, textInput: { searchOptions: true, placeholder: '查詢' } },
    'content/article-classification': { enable: false, showSearchBtn: true, textInput: { searchOptions: true, placeholder: '查詢用戶手機' } },
    'content/keyword': { enable: true, showSearchBtn: true, textInput: { searchOptions: true, placeholder: '請輸入關鍵字名稱' } },
    'content/album': { enable: true, showSearchBtn: true, textInput: { searchOptions: true, placeholder: '請輸入相簿名稱' } },
    'content/photo-tag': { enable: false, showSearchBtn: true, textInput: { searchOptions: false, placeholder: '請輸入名稱' } },
    'content/photo': { enable: false, showSearchBtn: true, textInput: { searchOptions: false, placeholder: '請輸入名稱' } },
    'content/catalog': { enable: true, showSearchBtn: true, textInput: { searchOptions: true, placeholder: '請輸入名稱' } },
    'bill/bill': { enable: true, showSearchBtn: true, textInput: { searchOptions: true, placeholder: '請輸入名稱' } },
    'bill/account': { enable: true, showSearchBtn: true, textInput: { searchOptions: true, placeholder: '請輸入名稱' } },
    'bill/account-transaction-detail': { enable: true, showSearchBtn: true, textInput: { searchOptions: true, placeholder: '請輸入名稱' } }
  },
  vender: {
    'order/reservation': { enable: true, showSearchBtn: true, textInput: { searchOptions: true, placeholder: '請輸入訂單編號' } },
    'order/unordered-reservation': { enable: true, showSearchBtn: true, textInput: { searchOptions: true, placeholder: '請輸入訂單編號' } },
    'order/reservation-quotation': { enable: false, showSearchBtn: false, textInput: { searchOptions: true, placeholder: '' } },
    'vender-staff': { enable: true, showSearchBtn: true, textInput: { searchOptions: true, placeholder: '請輸入服務員名稱' } },
    'marketing/notification': { enable: true, showSearchBtn: true, textInput: { searchOptions: true, placeholder: '查詢用戶手機' } },
    'bill/bill': { enable: true, showSearchBtn: true, textInput: { searchOptions: true, placeholder: '請輸入名稱' } }
  }
};

export default ['$state', '$http', 'session', 'currentUser', '$filter', 'ywTimezone', 'API_URL', 'Article',
  function factory ($state, $http, session, currentUser, $filter, ywTimezone, API_URL, Article) {
    // factory object
    const ywUtil = {
      date2Str: _datetime2Str('yyyy/MM/dd', ywTimezone.get()),
      datetime2Str: _datetime2Str('yyyy/MM/dd HH:mm', ywTimezone.get()),
      toDate: toDate,
      toDateStart: toDateStart,
      toDateEnd: toDateEnd,
      addDays: addDays
    };

    // static variables
    let distinctCount = 0;

    ywUtil.getTableHeader = function () {
      return getValueRelateToRoute(headerMap[_getRoleAndSetChairmanToModerator()] || []).slice();
    };

    ywUtil.getToolbarSettings = function () {
      return getValueRelateToRoute(toolbarMap[_getRoleAndSetChairmanToModerator()] || {
        pagination: true,
        printBtn: { enable: true },
        removeBtn: { enable: true },
        addBtn: { enable: true, label: '新增', size: 'md' }
      });
    };

    ywUtil.getSearchbarSettings = function () {
      return getValueRelateToRoute(searchbarMap[_getRoleAndSetChairmanToModerator()] || { enable: true, textInput: { enable: true, placeholder: '' } });
    };

    ywUtil.getSelectedWeeksLabel = days => {
      if (_.isEmpty(days)) {
        return '請選擇';
      }

      const options = ['星期一', '星期二', '星期三', '星期四', '星期五', '星期六', '星期日'];
      let str = '';
      let start = null;
      let end = null;
      [1, 2, 3, 4, 5, 6, 7].forEach((n, index) => {
        if (days.findIndex(day => day === n) > -1) {
          if (!start) {
            start = options[index];
          } else {
            end = options[index];
          }

          if (index === options.length - 1) { setDisplayStr(); } // last element
        } else {
          setDisplayStr();
          start = end = null;
        }
      });
      return str;

      function setDisplayStr () {
        if (start && !end) {
          str += (_.isEmpty(str) ? '' : ', ') + start;
        } else if (start && end) {
          str += (_.isEmpty(str) ? '' : ', ') + start + ' - ' + end[2];
        }
      }
    };

    ywUtil.convertBuildingOpts = function (buildingOpts) {
      if (!buildingOpts) return [];

      const result = [];
      buildingOpts.forEach(function (opt) {
        if (opt) { result.push(opt.title); }
      });
      return result;
    };

    ywUtil.getYearOpts = function () {
      const amount = 5;
      const currentYear = new Date().getYear() + 1900;
      return Array.apply(null, new Array(amount)).map(function (v, i) {
        return currentYear - (amount - 1 - i);
      });
    };

    ywUtil.distinctCount = function (count) {
      if (count) { distinctCount = count; }
      return distinctCount;
    };

    // 地下樓層 ex: -1 -> B1
    ywUtil.getFloorOpts = function (min, max) {
      const res = [];
      for (let i = min; i <= max; ++i) {
        if (i !== 0) {
          res.push({
            label: i < 0 ? `B${Math.abs(i)}` : String(i),
            value: i
          });
        }
      }
      return res;
    };

    ywUtil.getRoomOpts = function (max, isZeroStart) {
      if (_.isUndefined(max)) return [];
      const res = Array.apply(null, new Array(max + 1)).map(function (x, i) { return i; });
      if (!isZeroStart) { res.shift(); }
      return res;
    };

    ywUtil.getModeratorPermissionOptions = function (permissions, limitedByComm) {
      const permissionNameMap = {
        mail: '郵務管理',
        post: '公告管理',
        user: '住戶管理',
        guest: '訪客管理',
        gasMeter: '瓦斯抄表',
        paymentNotice: '繳費管理',
        resourceReservation: '公設預約',
        // 'dmImage': '回饋商品',
        // 'localStore': '特約商家',
        // 'localInfo': '周邊新聞',
        vote: '社區投票',
        privateMessage: '專屬訊息',
        'community-logo': 'APP社區形象圖',
        // 'community-profile': '社區基本設定',
        'community-serviceContact': '常用專線'
      };
      const commPermissions = _.get(currentUser.get(), 'currentHouse.community.permissions');

      const options = [];
      for (const key in permissionNameMap) {
        const option = { label: permissionNameMap[key], value: key };
        const permission = permissions.find(function (p) { return p.feature.name === key; });
        option.checked = (permission === undefined) ? true : !permission.hidden;
        if (limitedByComm) {
          const commPermission = commPermissions.find(function (p) { return p.feature.name === key; });
          option.disabled = (commPermission === undefined) ? true : commPermission.hidden;
        }
        options.push(option);
      }
      return options;
    };

    ywUtil.replaceNextline = function (text) {
      return text.replace(/\n/g, '<br>');
    };

    ywUtil.getActiveStatus = function (date) {
      if (!(date instanceof Date)) { date = new Date(date); }
      const distance = Date.now() - date.getTime();
      const daysDistance = Math.floor(distance / (24 * 60 * 60 * 1000));
      if (daysDistance < 7) return 'green';
      else if (daysDistance < 30) return 'yellow';
      else return 'red';
    };

    ywUtil.isSpecialCommunity = function () {
      return _.includes([
        '56e66335c04ef531d3737e8c',
        '57848f86179829ab627df224',
        '57bc04193d97eadf65349e9f',
        '57bff8e7218084e4651aad86'
      ], currentUser.getCommId());
    };

    ywUtil.getDiscountCategories = function () {
      return [
        { value: 'storeDiscount', label: '商品折扣' },
        { value: 'shipmentDiscount', label: '運費折扣' }
      ];
    };

    ywUtil.getCurrentHouseData = function (item) {
      const currentHouseData = {};
      currentHouseData.buildingString = '';

      if (currentUser.hasRole('admin')) {
        currentHouseData.currentHouse = $filter('short-addr')(item.currentHouse);
        currentHouseData.building = _.get(item, 'currentHouse.building', '');
        currentHouseData.floor = _.get(item, 'currentHouse.floor', '');
        currentHouseData.room = _.get(item, 'currentHouse.room', '');
        currentHouseData.familyCode = _.get(item, 'currentHouse.family.code', '');
        currentHouseData.isManager = _.get(item, 'currentHouse.isManager');
        if (_.size(item.houses) > 1) {
          _.forEach(item.houses, (house) => {
            currentHouseData.buildingString += `<div>${$filter('short-addr')(house)}(${house.community.title})</div>`;
          });
        }
      }

      if (currentUser.hasRole('chairman') || currentUser.hasRole('moderator')) {
        const houseInCurrentCommunity = findHouseInCurrentCommunity(_.get(item, 'houses'));
        currentHouseData.currentHouse = $filter('short-addr')(_.get(houseInCurrentCommunity, '[0]'));
        currentHouseData.building = _.get(houseInCurrentCommunity, '[0].building');
        currentHouseData.floor = _.get(houseInCurrentCommunity, '[0].floor');
        currentHouseData.room = _.get(houseInCurrentCommunity, '[0].room');
        currentHouseData.familyCode = _.get(houseInCurrentCommunity, '[0].family.code', '');
        currentHouseData.isManager = _.get(houseInCurrentCommunity, '[0].isManager');
        if (houseInCurrentCommunity.length > 1) {
          _.forEach(houseInCurrentCommunity, (house) => {
            currentHouseData.buildingString += `<div>${$filter('short-addr')(house)}</div>`;
          });
          const currentHouseCommunityId = _.get(item, 'currentHouse.community.objectId', item.currentHouse.community);
          if (currentHouseCommunityId === currentUser.getCommId()) {
            currentHouseData.currentHouse = $filter('short-addr')(item.currentHouse);
            currentHouseData.building = _.get(item, 'currentHouse.building', '');
            currentHouseData.floor = _.get(item, 'currentHouse.floor', '');
            currentHouseData.room = _.get(item, 'currentHouse.room', '');
            currentHouseData.familyCode = _.get(item, 'currentHouse.family.code', '');
            currentHouseData.isManager = _.get(item, 'currentHouse.isManager');
          }
        }
      }
      return currentHouseData;
    };

    ywUtil.getDiscountPromoType = function () {
      return [
        { value: 'firstOrderOnly', label: '首單限定' },
        { value: 'groupBuyOnly', label: '揪團限定' },
        { value: 'limitedNumOfTimes', label: '限制每人使用次數' }
      ];
    };

    ywUtil.getDiscountValueType = function () {
      return [
        { value: 'number', label: '固定金額折扣' },
        { value: 'percentage', label: '百分比折扣' }
      ];
    };

    ywUtil.getCarryConditions = function () {
      return [
        { value: 'roundOff', label: '四捨五入' },
        { value: 'roundUp', label: '無條件進位' },
        { value: 'roundDown', label: '無條件捨去' }
      ];
    };

    ywUtil.getPaymentCommentsType = function () {
      return [
        { value: 'modifyBoth', label: '商品金額異動' },
        { value: 'storeOnly', label: '應付店家異動' },
        { value: 'customerOnly', label: '應收客戶異動' }
      ];
    };

    ywUtil.getPaymentCommentsBlameOn = function () {
      return [
        { value: 'yowoo', label: '服務平台' },
        { value: 'serviceProvider', label: '服務商' },
        { value: 'toBuyStore', label: '店家' },
        { value: 'courier', label: '送貨員' }
      ];
    };

    ywUtil.timeCmp = function (date1, date2) {
      if (!date1 || !date2) return false;
      if (!(date1 instanceof Date)) { date1 = new Date(date1); }
      if (!(date2 instanceof Date)) { date2 = new Date(date2); }
      return date1.getTime() - date2.getTime();
    };

    function getRegionsParams (regions) {
      const resultArray = [];
      let regionIndex = 0;
      regions?.forEach((region) => {
        if (region.districts?.length) {
          region.districts?.forEach(district => {
            resultArray.push(`regions[${regionIndex}]={"county":"${region?.county || ''}","districts":"${district || ''}"}`);
            regionIndex++;
          });
        } else {
          resultArray.push(`regions[${regionIndex}]={"county":"${region?.county || ''}","districts":""}`);
          regionIndex++;
        }
      });
      return resultArray.join('&');
    }

    ywUtil.convertSimpleObjToQueryString = function (obj) {
      const CONNECT_SYMBOL = '&';
      return Object.keys(obj)
        .filter(k => !_.eq(undefined, obj[k]))
        .map(k => {
          if (Array.isArray(obj[k]) && k !== 'regions') {
            return obj[k].map((value) => encodeURIComponent(k) + '=' + encodeURIComponent(value)).join(CONNECT_SYMBOL);
          } else if (Array.isArray(obj[k]) && k === 'regions') {
            return getRegionsParams(obj[k]);
          }
          return encodeURIComponent(k) + '=' + encodeURIComponent(obj[k]);
        }
        )
        .join(CONNECT_SYMBOL);
    };

    ywUtil.isExpectedPickUpTimeGoingToExpired = function (order) {
      if (!order || !order.expectPickUpTime) return false;

      if (moment(order.expectedTime).diff(moment(order.expectPickUpTime).add(30, 'minutes')) < 0) {
        return true; // true when expectedTime is earlier then expectPickUpTime + 30mins
      } else {
        return false;
      }
    };

    ywUtil.getAgencies = function () {
      return [
        { value: 'toBuyStore', label: '店家' },
        { value: 'serviceProvider', label: '服務業者' }
      ];
    };

    ywUtil.getBillPaymentMethods = function () {
      return {
        virtualAccount: '虛擬帳戶',
        cash: '現金',
        eSunAccount: '玉山帳戶',
        tianmuAccount: '天母帳戶'
      };
    };

    ywUtil.getCallStatusList = function () {
      return {
        shouldCallAfter: '預計撥打時間',
        contentTooLong: '訂單長度過長',
        queueing: '已收到訂單',
        queued: '排程處理中',
        initiating: '已送至 Twillio',
        initiated: 'Twillio 已接收',
        ringing: '撥打鈴響中',
        failed: '空號',
        busy: '忙線中',
        'no-answer': '無人接聽',
        makeCallError: '撥打失敗！!',
        'in-progress': '通話進行中',
        noticeCompleted: '已通知店家接單',
        storeConfirmed: '店家確認接單',
        askForPeople: '請專人聯繫！!',
        noResponse: '接通後無回應',
        isMobilePhone: '行動電話不撥打',
        humanIntervention: '已人工介入處理'
      };
    };

    ywUtil.getNavigationPage = page => {
      switch (page) {
        case 'activity': return '平台活動';
        case 'privateMsg': return '個人訊息';
        case 'delivery': return '快送列表';
        case 'discovery': return '美食探索';
        case 'storeDetail': return '店家資訊';
        case 'storeMenu': return '店家菜單';
        case 'openLink': return '跳轉連結';
        case 'couponList': return '優惠碼';
        case 'inviteCode': return '分享免費吃';
        default: return '-';
      }
    };

    ywUtil.getNavigationAction = action => {
      switch (action) {
        case 'none': return '無動作';
        case 'openUrl': return '打開URL';
        case 'search': return '以＿＿查詢';
        case 'located': return '以＿＿定位';
        case 'call': return '撥打電話';
        case 'favorite': return '收藏店家';
        case 'addItem': return '優惠碼歸戶';
        default: return '-';
      }
    };

    ywUtil.getMailStatus = function () {
      return [
        { value: 'new', label: '未領件' },
        { value: 'pending', label: '退件待領' },
        { value: 'taken', label: '已領件' },
        { value: 'returning', label: '待退件' },
        { value: 'returned', label: '已退件' }
      ];
    };

    ywUtil.getFacilityReservationStatus = function () {
      return [
        { value: 'booked', label: '已預約' },
        { value: 'checkedIn', label: '已進場' },
        { value: 'checkedOut', label: '已離場' },
        { value: 'cancelled', label: '已取消' },
        { value: 'noShow', label: '已逾期' }
      ];
    };

    ywUtil.generateHourNumber = () => {
      const timeArray = [];
      for (let i = 1; i <= 48; i++) {
        const time = 0.5 * i;
        timeArray.push({ label: time, value: time });
      }
      return timeArray;
    };

    ywUtil.generateStoreVisibleDistanceKmNumber = () => {
      const kmNumberArray = [
        { label: 1, value: 1 },
        { label: 3, value: 3 },
        { label: 5, value: 5 },
        { label: 10, value: 10 }
      ];
      return kmNumberArray;
    };

    ywUtil.getCommunityPaymentMethod = () => {
      return [
        { value: 'cash', label: '現金' },
        { value: 'writeOffOverPayment', label: '溢繳沖銷' },
        { value: 'card', label: '信用卡' },
        { value: 'remittance', label: '虛擬帳戶' },
        { value: 'barcode', label: '超商繳款' }
      ];
    };

    ywUtil.getCommunityPaymentStoreType = () => {
      return [
        { value: 'spgateway', label: '智付通' },
        { value: 'kgi', label: '凱基' },
        { value: '71', label: '統一超' },
        { value: 'FA', label: '全家' },
        { value: 'OK', label: 'OK' },
        { value: 'HI', label: '萊爾富' }
      ];
    };

    ywUtil.getCommunityContractStatus = (status = null) => {
      const statusList = {
        none: '未簽約',
        inContract: '合約內',
        beforeContract: '未開始',
        afterContract: '應續約'
      };
      if (_.isNull(status)) return statusList;
      if (isStatusInList()) return statusList[status];
      return '狀態錯誤';

      function isStatusInList () {
        return _.some(_.keys(statusList), st => st === status);
      }
    };

    ywUtil.getBannerStatus = (status = null) => {
      const statusList = {
        inProgress: '進行中',
        unstarted: '未開始',
        finished: '已結束'
      };
      if (_.isNull(status)) return statusList;
      if (isStatusInList()) return statusList[status];
      return '狀態錯誤';

      function isStatusInList () {
        return _.some(_.keys(statusList), st => st === status);
      }
    };

    // 隱藏選項(hideOptions)目前是依據順序，有修改到這裡options順序的話要注意一下
    ywUtil.landingOptions = {
      get options () {
        return [
          {
            type: 'openUrl',
            page: 'none',
            pageName: '跳轉連結',
            actions: [
              { key: 'openUrl', name: '無動作' }
            ],
            param: [
              { key: 'url', name: '連結', type: 'text', required: true }
            ]
          },
          {
            type: 'couponList',
            page: 'couponList',
            pageName: '優惠券',
            actions: [
              { key: 'none', name: '無動作' },
              { key: 'addItem', name: '優惠券歸戶' },
              { key: 'fill', name: '填入優惠碼' }
            ],
            param: [
              {
                key: 'couponCode',
                name: '優惠碼',
                type: 'text',
                requiredActions: ['addItem', 'fill']
              }
            ]
          },
          {
            type: 'serviceClassification',
            page: 'serviceClassification',
            pageName: '服務分類',
            actions: [
              { key: 'introduceService', name: '進入介紹頁' },
              { key: 'bookService', name: '進入下訂流程' }
            ],
            param: [
              {
                key: 'classificationId',
                name: '選擇服務分類',
                type: 'select',
                required: true
              }
            ]
          },
          {
            type: 'article',
            page: 'article',
            pageName: '文章頁',
            actions: [
              { key: 'none', name: '無動作' }
            ],
            param: [
              {
                key: 'articleId',
                name: '選擇文章',
                type: 'searchable-obj-picker',
                required: true,
                resource: Article,
                filterField: 'title',
                queryField: 'title',
                subTitleField: 'urlId',
                addressFilter: false
              }
            ]
          },
          {
            type: 'famiCode',
            page: 'famiCodeRedemption',
            pageName: '全家優惠',
            actions: [
              { key: 'openUrl', name: '無動作' }
            ],
            param: [
              { key: 'title', name: '優惠標題', type: 'text', required: true },
              { key: 'validDate', name: '使用期限', type: 'date', required: true, toDateEnd: true },
              { key: 'barcode', name: '條碼', type: 'text', required: true },
              { key: 'sn', name: '序號', type: 'text', required: true }
            ]
          }
        ];
      },
      findCurrentOptionIndex (type, page) {
        if (_.isEmpty(type) || _.isEmpty(page)) return 0;
        return _.findIndex(this.options, option => {
          return option.page === page && option.type === type;
        });
      },
      findNames (page, action) {
        const option = this.options.find(option => option.page === page);
        const actionObj = _.get(option, 'actions', [])
          .find(actionObj => actionObj.key === action);
        return {
          pageName: _.get(option, 'pageName', ''),
          actionName: _.get(actionObj, 'name', '')
        };
      }
    };

    ywUtil.displayRestriction = {
      none: '不限',
      community: '限特定社區',
      range: '中心點範圍',
      resident: '特定用戶身份'
    };

    ywUtil.syEstateTiers = [
      { label: '社區4.0', value: 'sinyiEstate' },
      { label: '重點社區', value: 'keyEstate' },
      { label: '合作社區', value: 'coopEstate' }
    ];

    ywUtil.residentRestriction = {
      joinedCommunity: '已加入任一社區',
      notJoinedCommunity: '未加入社區',
      verifiedPhone: '已驗證電話',
      notVerifiedPhone: '未驗證電話'
    };

    ywUtil.giftTypes = {
      message: '純訊息',
      communityCoin: '幫幣'
    };

    ywUtil.paymentPeriods = [
      { label: '1(月繳)', value: 1 },
      { label: '2(雙月繳)', value: 2 },
      { label: '3(季繳)', value: 3 },
      { label: '4', value: 4 },
      { label: '6(半年繳)', value: 6 },
      { label: '12(年繳)', value: 12 }
    ];

    ywUtil.getCommunityInfoParams = [
      [
        'applicationDate', 'serialId', 'tags', 'source', 'title', 'phone', 'fax',
        'addressCity', 'addressArea', 'addressDetail', 'addressVillage', 'addressNeighborhood', 'loc',
        'contactName', 'contactPhone', 'contactEmail',
        'representativeName', 'representativePhone', 'representativeEmail',
        'agentName', 'consultant', 'unitOwnerAssemblyDate',
        'securityCompanyName', 'securityMode',
        'familyTotal', 'upperStoryTotal', 'lowerStoryTotal', 'passengerElevatorAmount', 'freightElevatorAmount',
        'extWallsMaterialCode', 'bikeParkingType', 'parkingSpotChargingType', 'managementChargingType', 'facilityItem',
        'includingBarrierFree', 'barrierFreeItem',
        'trashProcessingType', 'trashProcessingItem',
        'note', 'sinyiCommunities'
      ], // 社區基本資料
      ['propertyManagementInfo'], // 物業資料
      ['user'], // 社區總管設定
      ['contract'], // 合約慨況
      ['bank', 'taxIdNumber', 'feePaidByCommunity'], // 匯款資訊
      ['webAdminAnnouncement', 'forSinyiStore', 'relatedCommunities', 'allowReplyPrivateMsg', 'isConfirmPwdInPayment', 'paymethods', 'isPaidByReleaseDate'] // 客制功能設定
    ];

    ywUtil.getRouletteSettingParams = [
      ['title', 'activeTimeStart', 'activeTimeEnd', 'model', 'sinyiSetting', 'ruleSetting', 'items', 'termColor', 'buttonBgColor'], // 轉盤設定
      ['imageInfo', 'termColor', 'buttonBgColor'], // 外觀設定
      ['imageInfo', 'termColor', 'buttonBgColor'] // 外觀設定
    ];

    ywUtil.billStatus = {
      established: '出帳',
      succeeded: '繳費成功',
      failed: '繳費失敗',
      refunded: '退款'
    };

    ywUtil.giftCodeStatus = {
      pending: '尚未指派',
      reserved: '保留中',
      assigned: '已轉出',
      redeemed: '已兌換',
      abandoned: '錯誤'
    };

    ywUtil.billPaymentWayForMultiFilter = [
      { label: '現金', value: 'cash' },
      { label: '溢繳沖銷', value: 'writeOffOverPayment' },
      { label: '信用卡', value: 'card' },
      { label: '虛擬帳戶', value: 'remittance' },
      { label: '超商-全家', value: 'FA' },
      { label: '超商-711', value: '71' },
      { label: '超商-OK', value: 'OK' },
      { label: '超商-萊爾富', value: 'HI' }
    ];

    ywUtil.getAdminNotificationCategory = [
      { label: '公告關鍵字', value: 'postKeyword' },
      { label: '後台操作', value: 'webAdminOperation' },
      { label: '社區合約到期', value: 'estateContractExpiry' },
      { label: '住戶開通率', value: 'propertyActivationRate' },
      { label: '特店合約到期', value: 'coopStoreContractExpiry' }
    ];
    ywUtil.getVendorInfoParams = [
      [
        'name', 'shortname', 'vatNo', 'contract', 'address', 'owner', 'accountReceiptMails', 'phone', 'phoneExtension', 'fax',
        'orderContacts', 'url', 'capital', 'securityDeposit', 'isActiveSmsSending', 'urlId', 'sortOrder', 'note', 'employeeCount'
      ], // 基本設定
      ['notServiceIn'], // 服務方案與派案
      ['spgateway', 'spgatewayFor3DVerification', 'bankAccount'], // 刷卡金流設定
      ['contract', 'introductionInfo', 'customerBudget', 'qualityTags', 'serviceItems', 'isDisplayGotoReservation', 'gotoReservationDefaultName', 'navData'], // 介紹資訊
      ['acceptanceSetting', 'labels'] // 其他設定
    ];

    ywUtil.getAdminNotificationStatus = [
      { label: '未處理', value: 'pending' },
      { label: '處理中', value: 'processing' },
      { label: '已處理', value: 'finished' }
    ];

    ywUtil.getFeatureName = [
      { label: '啟用社區服務', value: 'communityService' },
      { label: '郵務管理', value: 'mail' },
      { label: '繳管理費', value: 'paymentNotice' },
      { label: '生活圈', value: 'life-circles' },
      { label: '公設預約', value: 'facilityReservation' },
      { label: '社區公告', value: 'post' },
      { label: '專屬訊息', value: 'privateMessage' },
      { label: '特約商店', value: 'localStore' },
      { label: '有無外送', value: 'deliveryApp' },
      { label: '常用專線', value: 'contactWindow' },
      { label: '瓦斯抄表', value: 'gasMeter' },
      { label: '社區投票', value: 'vote' },
      { label: '生活小工具', value: 'lifeTool' },
      { label: '設定', value: 'appSetting' },
      { label: '物品借用', value: 'lendItem' },
      { label: '管委會專區', value: 'committeeOnly' },
      { label: '村里公佈欄', value: 'village-post' },
      { label: '活動', value: 'event' },
      { label: '專屬貼圖', value: 'lcb-camera' },
      { label: '繳學雜費', value: 'ctbc-tuitionFee-payment' }
    ];

    ywUtil.estateTypeOptions = [
      { value: 'key', label: '重點社區' },
      { value: 'coop', label: '合作社區' },
      { value: 'real', label: '簽約使用社區' }
    ];

    ywUtil.localStoreReviewStates = [
      { label: '未審核', value: 'pending' },
      { label: '審核中', value: 'reviewing' },
      { label: '通過', value: 'approved' },
      { label: '未通過', value: 'rejected' }
    ];

    ywUtil.localStoreReviewStages = [
      { label: '第一階段(資料審查)', value: 'docReviewed' },
      { label: '第二階段(社服拜訪)', value: 'surveyed' }
    ];
    ywUtil.generateYwPopupOptions = data => {
      return data.map(item => {
        return { value: item, label: item };
      });
    };

    ywUtil.keyboardWithShiftMap = {
      1: '!',
      2: '@',
      3: '#',
      4: '$',
      5: '%',
      6: '^',
      7: '&',
      8: '*',
      9: '(',
      0: ')',
      Minus: '_',
      Equal: '+',
      BracketLeft: '{',
      BracketRight: '}',
      Backslash: '|',
      Semicolon: ':',
      Quote: '"',
      Comma: '<',
      Period: '>',
      Slash: '?',
      Backquote: '~'
    };

    ywUtil.keyboardMap = {
      Minus: '-',
      Equal: '=',
      BracketLeft: '[',
      BracketRight: ']',
      Backslash: '\\',
      Semicolon: ';',
      Quote: "'",
      Comma: ',',
      Period: '.',
      Slash: '/',
      Backquote: '`'
    };

    ywUtil.cloneText = text => {
      const copyText = document.createElement('textarea');
      document.body.appendChild(copyText);
      copyText.value = text;
      console.log(copyText.value);
      copyText.select();
      document.execCommand('copy');
      copyText.remove();
    };

    ywUtil.downloadFile = async (fileSrc, name) => {
      const file = await fetch(fileSrc); // eslint-disable-line
      const fileBlob = await file.blob();
      const fileUrl = URL.createObjectURL(fileBlob); // eslint-disable-line

      const link = document.createElement('a');
      link.href = fileUrl;
      link.download = name;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };

    ywUtil.exportCouponCsv = (couponIds) => {
      const form = document.createElement('form');
      form.method = 'POST';
      form.action = API_URL + 'coupons/codeCsv?access_token=' + currentUser.getToken();

      const select = document.createElement('select');
      select.name = 'couponIds';
      select.multiple = 'multiple';
      for (const id of couponIds) {
        const option = document.createElement('option');
        option.value = id;
        option.selected = 'selected';
        select.appendChild(option);
      }
      form.appendChild(select);

      const input = document.createElement('input');
      input.type = 'text';
      input.name = 'mode';
      input.value = 'forCouponCreated';
      form.appendChild(input);

      document.body.appendChild(form);
      form.submit();
      form.remove();
    };

    ywUtil.getDetailsFromContactAddress = (addressInfo) => {
      const fields = [
        'village',
        'road',
        'lane',
        'alley',
        'number',
        'suffixNumber',
        'subNumber',
        'floor',
        'suffixFloorNumber',
        'unitNumber'
      ];
      // set null & undefined to empty string
      fields.forEach((field) => {
        addressInfo[field] = addressInfo[field] || '';
      });
      let number = addressInfo.number;
      if (number) {
        number += addressInfo.suffixNumber && `-${addressInfo.suffixNumber}`;
        number += addressInfo.subNumber ? `號之${addressInfo.subNumber}` : '號';
      }
      let floor = addressInfo.floor;
      if (floor) {
        floor += addressInfo.suffixFloorNumber && `-${addressInfo.suffixFloorNumber}`;
        floor += addressInfo.unitNumber ? `樓之${addressInfo.unitNumber}` : '樓';
      }
      return addressInfo.village +
        addressInfo.road +
        (addressInfo.lane ? addressInfo.lane + '巷' : '') +
        (addressInfo.alley ? addressInfo.alley + '弄' : '') +
        number +
        floor;
    };

    ywUtil.getFullAddressFromContactAddress = (addressInfo = {}) => {
      return addressInfo.county +
        addressInfo.district +
        ywUtil.getDetailsFromContactAddress(addressInfo);
    };

    ywUtil.initSearchBarOptions = ($scope, searchBarOptions = [], options = {}) => {
      const { defaultOptionName, defaultValue } = options;
      $scope.searchBarOptions = searchBarOptions;
      $scope.searchBarOptionsActive = defaultOptionName
        ? _.find(searchBarOptions, (option) => option.params === defaultOptionName)
        : _.head($scope.searchBarOptions);
      if (defaultValue) {
        $scope.mainAction.queryParams[$scope.searchBarOptionsActive.params] = defaultValue;
        $scope.mainAction.customSearchText = defaultValue;
      }

      $scope.changeSearchBarActiveOption = changeSearchBarActiveOption;

      function changeSearchBarActiveOption (currentOption, newOption) {
        $scope.searchBarOptionsActive = newOption;
        $scope.mainAction.queryParams[newOption.params] = $scope.mainAction.queryParams[currentOption.params];
        _.unset($scope.mainAction.queryParams, currentOption.params);
      }

      $scope.customTextSearchCb = _.debounce(function (text, page, name) {
        $scope.mainAction.queryParams[$scope.searchBarOptionsActive.params] = _.isEmpty(text)
          ? undefined
          : text;
        $scope.mainAction.filterQuery(text, page, name);
      }, 1000, {
        leading: true,
        trailing: false
      });
      $scope.customTextChangeCb = function (text) {
        $scope.mainAction.queryParams[$scope.searchBarOptionsActive.params] = _.isEmpty(text)
          ? undefined
          : text;
      };
    };

    ywUtil.getDefaultOriginOptions = () => {
      return [
        { label: '居家自建', value: 'serviceAdmin' },
        { label: '廠商自建', value: 'vender' },
        { label: '信義自建', value: 'sinyiApi' },
        { label: 'iOS', value: 'ios' },
        { label: 'Android', value: 'android' },
        { label: 'web', value: 'web' }
      ];
    };

    //
    // private functions
    //

    function getValueRelateToRoute (map) {
      if (!map) return null;
      const path = $state.current.name;

      if (!_.isEmpty(map) && !_.isEmpty(path) && map[path.split('.')[1]]) { return _.cloneDeep(map[path.split('.')[1]]); }

      return null;
    }

    function _getRoleAndSetChairmanToModerator () {
      const currentRole = currentUser.getRole();
      if (!currentRole) { return ''; }

      return currentRole;
    }

    function _datetime2Str (format, tz) {
      return function (obj, customFormat) {
        if (customFormat) return obj ? $filter('date')(obj, customFormat, tz) : '';
        return obj ? $filter('date')(obj, format, tz) : '';
      };
    }

    function toDate (date) {
      const dateObj = date || new Date();

      return moment(dateObj).toDate();
    }

    function toDateStart (date) {
      return date && moment(toDate(date)).startOf('day').toDate();
    }

    function toDateEnd (date) {
      return date && moment(toDate(date)).endOf('day').toDate();
    }

    function addDays (diff, startDate) {
      return moment(toDate(startDate)).add(diff, 'days').toDate();
    }

    function findHouseInCurrentCommunity (houses) {
      return _.filter(houses, (house) => {
        const houseCommunityId = _.get(house.community, 'objectId', house.community);
        return houseCommunityId === currentUser.getCommId();
      });
    }

    return ywUtil;
  }
];
