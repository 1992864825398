export default ['$async', 'confirmDialog', 'ywEvent', 'ywUtil', 'Restangular', 'Vender', 'ServiceClassification', 'AdvancedFilterItem', 'address',
  function albumUtilFactory ($async, confirmDialog, ywEvent, ywUtil, Restangular, Vender, ServiceClassification, AdvancedFilterItem, address) {
    const util = {};

    // sources 為當前頁面持有的相簿總體，批次更新完後要再把資料寫回被更新的相簿中
    util.openBatchUpdate = async (venderIds = [], sources = []) => {
      const fieldOptions = [
        {
          value: 'isVisible',
          label: '資訊顯示',
          default: { data: true }
        },
        {
          value: 'isFilterListVisible',
          label: '列表顯示',
          default: { data: true }
        },
        {
          value: 'serviceCounties',
          label: '服務縣市',
          default: { action: 'add', data: [] }
        },
        {
          value: 'labels',
          label: '廠商標籤',
          default: { action: 'add', data: [] }
        },
        {
          value: 'navData',
          label: '前往預約按鈕跳轉',
          default: { data: undefined }
        }
      ];

      const dialogData = {
        title: '批次設定廠商',
        templateUrl: '/view/dialog/batch-update-vender.html',
        fieldOptions,
        targetField: _.head(fieldOptions).value,
        onTargetFieldChange: $async(async (targetField) => {
          const option = _.find(fieldOptions, opt => opt.value === targetField);
          dialogData.confirmValue = _.clone(_.get(option, 'default', {}));

          if (targetField === 'advancedFilterItems') {
            const classifications = await ServiceClassification.getList({ limit: 999 });
            dialogData.serviceClassificationOptions = classifications.map(item => ({ label: item.name, value: item._id }));
          }
        }),
        onServiceClassificationChange: $async(async (id) => {
          const advancedFilterItems = await AdvancedFilterItem.getList({ serviceClassification: id });
          dialogData.advancedFilterItemOptions = advancedFilterItems.map(item => ({ label: item.name, value: item._id }));
        }),
        resourceMap: {
          label: Restangular.service('labels')
        },
        countyOptions: address.getSortedCounties,
        confirmValue: _.clone(_.head(fieldOptions).default)
      };
      const result = await confirmDialog.openConfirm(dialogData);
      const target = dialogData.targetField;
      return handleBatchUpdate(target, venderIds, result, sources);
    };

    const handleBatchUpdate = async (target, venderIds, params, sources) => {
      if (target === 'isVisible') {
        const result = await Vender
          .one('visibleState')
          .customPUT({ venderIds, target: 'isVisible', isVisible: params.data });
        if (result.error) {
          ywEvent.emit('ALERT', result.error);
        } else {
          _.forEach(sources, (item, index) => {
            if (!item || !_.includes(venderIds, item._id)) { return; }
            if (!item.introductionInfo) {
              item.introductionInfo = {};
            }
            item.introductionInfo.isVisible = params.data;
            sources[index] = item.clone();
          });
          ywEvent.emit('SUCCESS', '設定完成');
        }
      } else if (target === 'isFilterListVisible') {
        const result = await Vender
          .one('visibleState')
          .customPUT({ venderIds, target: 'isFilterListVisible', isVisible: params.data });
        if (result.error) {
          ywEvent.emit('ALERT', result.error);
        } else {
          _.forEach(sources, (item, index) => {
            if (!item || !_.includes(venderIds, item._id)) { return; }
            if (!item.introductionInfo) {
              item.introductionInfo = {};
            }
            item.introductionInfo.isFilterListVisible = params.data;
            sources[index] = item.clone();
          });
          ywEvent.emit('SUCCESS', '設定完成');
        }
      } else if (target === 'serviceCounties') {
        const result = await Vender
          .one('countiesIntro')
          .customPUT({ venderIds, serviceCounties: params.data, method: params.action });
        if (result.error) {
          ywEvent.emit('ALERT', result.error);
        } else {
          const counties = params.data;
          _.forEach(sources, (item, index) => {
            if (!item || !_.includes(venderIds, item._id)) { return; }
            if (params.action === 'add') {
              if (!item.introductionInfo) {
                item.introductionInfo = {};
              }
              item.introductionInfo.serviceCounties = _.unionWith(
                (item.introductionInfo.serviceCounties || []),
                counties,
                (a, b) => a === b
              );
            } else if (params.action === 'remove') {
              item.introductionInfo.serviceCounties = _.differenceWith(
                (item.introductionInfo.serviceCounties || []),
                counties,
                (a, b) => a === b
              );
            }
            sources[index] = item.clone();
          });
          ywEvent.emit('SUCCESS', '設定完成');
        }
      } else if (target === 'labels') {
        const result = await Vender
          .one('labels')
          .customPUT({ labels: params.data, venders: venderIds, action: params.action });
        replaceBatchUpdatedResultByKey(result, venderIds, 'labels', sources);
      } else if (target === 'advancedFilterItems') {
        const result = await Vender
          .one('venderAdvancedFilters')
          .customPUT({ ...params.data, venderIds, action: params.action });
        if (result.error) {
          ywEvent.emit('ALERT', result.error);
        } else {
          ywEvent.emit('SUCCESS', '設定完成');
        }
      } else if (target === 'navData') {
        const result = await Vender
          .one('navData')
          .customPUT({ venderIds, navData: params.data });
        replaceBatchUpdatedResult(result, venderIds, { navData: params.data }, sources);
      }
    };

    const replaceBatchUpdatedResult = (result, venderIds, updatedData, sources) => {
      if (result.error) {
        ywEvent.emit('ALERT', result.error);
      } else {
        _.forEach(sources, (item, index) => {
          if (!item || !_.includes(venderIds, item._id)) { return; }
          _.assign(item, updatedData);
          sources[index] = item.clone();
        });
        ywEvent.emit('SUCCESS', '設定完成');
      }
    };

    const replaceBatchUpdatedResultByKey = (result, venderIds, key, sources) => {
      if (result.error) {
        ywEvent.emit('ALERT', result.error);
      } else {
        _.forEach(sources, (item, index) => {
          const resultItem = _.find(result, (vender) => vender._id === item._id);
          if (!resultItem) return;
          item[key] = resultItem[key];
          sources[index] = item.clone();
        });
        ywEvent.emit('SUCCESS', '設定完成');
      }
    };

    return util;
  }
];
