import moment from 'moment';

export default ['Restangular', function factory (Restangular) {
  Restangular.addElementTransformer('catalogs', false, _transformer);
  const Catalog = Restangular.service('catalogs');
  return Catalog;

  function _transformer (item) {
    if (item.error) return item;
    if (typeof item === 'number') return item; // count

    item._columns = [
      { anchorType: 'checkbox' },
      { anchorType: 'true-or-false', isTrue: _.get(item, 'isVisible') },
      { anchorType: 'true-or-false', isTrue: _.get(item, 'isVenderListSearchVisible') },
      _.get(item, 'sort', ''),
      _.get(item, 'name', ''),
      moment(_.get(item, 'operationLog.at')).format('YYYY/MM/DD HH:mm'),
      _.get(item, 'operationLog.byName'),
      { anchorType: 'icon-button', icon: 'edit', name: 'edit' }
    ];

    return item;
  }
}];
