'use strict';

// template
export { mainWrapperTmpl } from './template/mainpage-wrapper.html';
export { subWrapperTmpl } from './template/subpage-wrapper.html';
export { navbarTmpl } from './template/navbar.html';
export { leftNavTmpl } from './template/left-nav.html';
export { listWrapperTmpl } from './template/list-wrapper.html';
export { listTmpl } from './template/list.html';
export { pagerToolbarTmpl } from './template/pager-toolbar.html';
export { actionTmpl } from './template/action.html';
export { reservationOrderFilterTmpl } from './template/reservation-order-filter.html';
export { bannerFilterTmpl } from './template/banner-filter.html';
export { couponFilterTmpl } from './template/coupon-filter.html';
export { billFilterTmpl } from './template/bill-filter.html';
export { customContentSettingTmpl } from './template/custom-content-setting.html';

// page
export { loginTmpl } from './page/login.html';
export { systemSettingTmpl } from './page/service-admin/system-setting.html';
export { websiteContentLayoutTmpl } from './page/service-admin/website-content-layout.html';
export { websiteContentAlbumTmpl } from './page/service-admin/website-content-album.html';
export { adminTabsetActionPageTmpl } from './page/admin/tabset-action-page.html';
export { adminTabsetActionPageTmplWithTabWatcher } from './page/admin/tabset-action-page-with-tab-watcher.html';
export { unorderedReservationFilterTmpl } from './page/unordered-reservation-filter.html';
export { unorderedReservationQuotationTmpl } from './page/service-admin/unordered-quotation.html';
export { venderAdvancedFilterItemTmpl } from './page/service-admin/vender-advanced-filter-item.html';
// export {communityFilterTmpl} from './page/community-filter.html';
// export {statusHintTmpl} from './page/status-hint.html';
// export {userTmpTmpl} from './page/user-tmp.html';
// export {adminToBuySettingTmpl} from './page/admin/to-buy-config.html';
// export {adminRegionSettingTmpl} from './page/admin/region-setting.html';
// export {adminStatisticsToBuyOrderTmpl} from './page/admin/statistics/to-buy-order.html';
// export {adminStatisticsCommunityTmpl} from './page/admin/statistics/community.html';
// export {adminStatisticsActiveUserTmpl} from './page/admin/statistics/active-user.html';
// export {adminServiceProviderAccountRegionSettingTmpl} from './page/admin/service-provider/region-setting.html';
// export {adminStorePictureCheckTmpl} from './page/admin/store-picture-check.html';
// export {adminWaitingPointTmpl} from './page/admin/waiting-point.html';
// export {adminGroupsDiscountSettingTmpl} from './page/admin/groups-discount-setting.html';
// export {adminDynamicLinkGeneratorTmpl} from './page/admin/dynamic-link-generator.html';
// export {adminCommunityMarketingSettingTmpl} from './page/admin/community-marketing-setting.html';
// export {adminSystemSettingTmpl} from './page/admin/system-setting.html';
// export {adminBankStatementPageTmpl} from './page/admin/bank-statement.html';
// export {adminCommunitySettingTmpl} from './page/admin/community-setting.html';
// export {distOrderFilterTmpl} from './page/distributor/order-status/customize-filter.html';
// export {spOrderFilterTmpl} from './page/service-provider/order-filter.html';
// export {spSettingTmpl} from './page/service-provider/setting.html';
// export {courierAssignerDialogContent} from './page/service-provider/courier-assigner-dialog-content.html';
// export {discountCreatorDialogContent} from './page/service-provider/discount-creator-dialog-content.html';
// export {spDiscountSettingTmpl} from './page/service-provider/discount-setting.html';
// export {spRegionDiscountSettingTmpl} from './page/service-provider/region-discount-setting.html';
// export {spStatisticsCourierTmpl} from './page/service-provider/statistics-courier.html';
// export {spCourierLocationTmpl} from './page/service-provider/courier-location.html';
// export {spCourierLocationHistoryTmpl} from './page/service-provider/courier-location-history.html';
// export {spSubOrder} from './page/service-provider/sub-order.html';
// export {spPictureManagementTmpl} from './page/service-provider/picture-upload.html';
// export {modProfileWrapperTmpl} from './page/moderator/community/profile-wrapper.html';
// export {modBuildingOptTmpl} from './page/moderator/community/profile/building-options.html';
// export {modHouseMaxTmpl} from './page/moderator/community/profile/house-max.html';
// export {modHiddenRoomTmpl} from './page/moderator/community/profile/hidden-room.html';
// export {modResidentPostSwitchTmpl} from './page/moderator/community/profile/resident-post-switch.html';
// export {modCommLogoTmpl} from './page/moderator/community/logo.html';
// export {modPaymentDetailTmpl} from './page/moderator/payments/payment-detail.html';
// export {modPaymentListNoticeTmpl} from './page/moderator/payments/notice.html';
// export {modResourceReservationListTmpl} from './page/moderator/resource-reservation/customize-list-content.html';
// export {modResourceReservationEditDialogTmpl} from './page/moderator/resource-reservation/edit-resource-dialog.html';
// export {modMultiPrivateMsgDialogTmpl} from './page/moderator/user/multi-private-msg-dialog.html';
// export {modPrivateMsgTmpl} from './page/moderator/user/private-message.html';
// export {modDMManagementTmpl} from './page/moderator/dm-management.html';
// export {couponAssignerDialogContent} from './page/coupon-assigner-dialog-content.html';
// export {pushMessageDialogContent} from './page/push-message-dialog-content.html';
// export {toBuyStoreStatisticsChartsTmpl} from './page/to-buy-store/statistics-charts.html';
// export {modPaymentSettingTmpl} from './page/moderator/payments/payment-setting.html';
// export {modDashboardTmpl} from './page/moderator/dashboard.html';

// directive
export { userSelectorTmpl } from './directive/yw-user-selector.html';
export { customOptionCreationDialogTmpl } from './directive/custom-option-creation-dialog.html';
export { popupDatePickerTmpl } from './directive/popup-date-picker.html';
export { popupSelectionMenuTmpl } from './directive/popup-selection-menu.html';
// export { waitingTimeTable } from './directive/waiting-time-table.html';
// export { toBuyBillReceiptDialog } from './directive/to-buy-bill-receipt-dialog.html';
// export { periodSettingTmpl } from './directive/period-setting.html';
export { imagePreviewDialog } from './directive/image-preview-dialog.html';
// export { mailSignHistoryDialog } from './directive/mail-sign-history-dialog.html';
// export { houseSelector } from './directive/house-selector.html';
export { urlShorter } from './directive/url-shorter.html';
// export { fillGoogleForm } from './directive/fill-google-form.html';
export { dynamicLinkGenerater } from './directive/dynamic-link-generater.html';
// export { gameGenerater } from './directive/game-generater.html';
export { timePeriodInWeek } from './directive/time-period-in-week.html';
export { timepartSetting } from './directive/timepart-setting.html';
export { selectTimeInWeek } from './directive/select-time-in-week.html';
export { timepartPicker } from './directive/timepart-picker.html';
// export { phoneVerification } from './directive/phone-verification.html';
export { expectedDatePicker } from './directive/expected-date-picker.html';
export { valuateDatePicker } from './directive/valuate-date-picker.html';

// dialog
export { timelineDialogTmpl } from './dialog/timeline-dialog.html';
export { contactDialogTmpl } from './dialog/contact-dialog.html';
// export { communitySelectDialogTmpl } from './dialog/community-select-dialog.html';
export { searchableObjSelectDialogTmpl } from './dialog/searchable-obj-select-dialog.html';
export { confirmDialogTmpl } from './dialog/confirm-dialog.html';
// export { confirmSystemGroupCreationTmpl } from './dialog/confirm-system-group-creation.html';
// export { confirmSystemGroupModificationTmpl } from './dialog/confirm-system-group-modification.html';
export { dayPickerTmpl } from './dialog/day-picker-dialog.html';
// export { genPaymentTmpl } from './dialog/gen-payment.html';
export { payBillTmpl } from './dialog/pay-bill.html';
export { paymentsSelectionDialogTmpl } from './dialog/payments-selection-dialog.html';
export { removeBillTmpl } from './dialog/remove-bill.html';
export { sendBillTmpl } from './dialog/send-bill.html';
export { serviceTimeSettingDialogTmpl } from './dialog/service-time-setting-dialog.html';
// export { signMailTmpl } from './dialog/sign-mail.html';
// export { toBuyRefundingDialogTmpl } from './dialog/to-buy-refunding-dialog.html';
export { singleCoordinateDialog } from './dialog/single-coordinate-dialog.html';
export { tagSettingDialogContentTmpl } from './dialog/tag-setting-dialog-content.html';
// export { toBuyTagManageDialogContentTmpl } from './dialog/to-buy-tag-manage-dialog-content.html';
export { popupSelectionFactoryTmpl } from './dialog/popup-selection-factory.html';
// export { productShlefDialogContentTmpl } from './dialog/product-shelf-dialog-content.html';
// export { productSoldOutDialogContentTmpl } from './dialog/product-sold-out-dialog-content.html';
// export { guestLeaveDialogContentTmpl } from './dialog/guest-leave-dialog-content.html';
// export { handlePaidDialog } from './dialog/product-order/handle-paid-dialog.html';
// export { handleShippingDialog } from './dialog/product-order/handle-shipping-dialog.html';
// export { handleShippedInTrialDialog } from './dialog/product-order/handle-shipped-inTrial-dialog.html';
// export { handleShippedDialog } from './dialog/product-order/handle-shipped-dialog.html';
// export { handleReturningDialog } from './dialog/product-order/handle-returning-dialog.html';
// export { regionEditContentTmpl } from './dialog/region-edit-content.html';
// export { toBuyHandlingWithPickupDialogTmpl } from './dialog/to-buy-handling-with-pickup-dialog.html';
// export { toBuyPickupTimeSelectDialogTmpl } from './dialog/to-buy-pickup-time-select-dialog.html';
// export { toBuyStoreLinkSettingDialogTmpl } from './dialog/store-link-setting.html';
// export { landmarkSelectionDialogTmpl } from './dialog/landmark-selection.html';
// export { toBuyOrderDetailDialogTmpl } from './dialog/to-buy-order-detail-dialog.html';
// export { productOrderSetupDialogTmpl } from './dialog/product-order-setup-dialog-content.html';
// export { joinedActivityStoresTmpl } from './dialog/joined-activity-stores.html';
// export { billPreviewDialog } from './dialog/bill-preview-dialog.html';
// export { editSearchTagAndSynonymTmpl } from './dialog/edit-search-tag-and-synonym.html';
export { billReceiptsChangeReason } from './dialog/bill-receipts-change-reason.html';
// export { extraServiceApplicationApprove } from './dialog/extra-service-application-approve.html';
// export { extraServiceApplicationReject } from './dialog/extra-service-application-reject.html';
export { multiSearchableSelectDialog } from './dialog/multi-searchable-select-dialog.html';
export { multiSelectDialog } from './dialog/multi-select-dialog.html';
// export { waitingPointEditContentDialog } from './dialog/waiting-point-edit-content.html';
// export { storeGroupAdjustDialog } from './dialog/store-group-adjust.html';
// export { courierApplicationVerifyDialog } from './dialog/courier-application-verify.html';
// export { autoDispatchInfoDialog } from './dialog/auto-dispatch-info.html';
// export { transferRecordDetail } from './dialog/transfer-record-detail.html';
// export { multiDistanceShipmentEdit } from './dialog/multi-distance-shipment-edit.html';
export { qrCodeDialog } from './dialog/qrcode-dialog.html';
export { activityContent } from './dialog/activity-content.html';
// export { storeColorTagSetup } from './dialog/store-color-tag-setup.html';
// export { activityDetailDialogTmpl } from './dialog/activity-detail-dialog.html';
// export { courierDiscardLogsTmpl } from './dialog/courier-discard-logs.html';
export { batchUpdateStoreTmpl } from './dialog/batch-update-store.html';
export { batchUpdateDialogTmpl } from './dialog/batch-update-dialog.html';
export { navigationSettingTmpl } from './dialog/navigation-setting.html';
export { pushMessageDetails } from './dialog/push-message-details.html';
export { singleInputDialog } from './dialog/single-input.html';
export { denyReservationDialog } from './dialog/deny-reservation-dialog.html';
// export { managementFeeReceiveDialog } from './dialog/management-fee-receive.html';
// export { managementFeeReceiveCheckDialog } from './dialog/management-fee-receive-check.html';
// export { managementFeeReceiptDialog } from './dialog/management-fee-receipt.html';
// export { printMailNoticeDialog } from './dialog/print-mail-notice.html';
// export { postEventDialog } from './dialog/post-event-dialog.html';
// export { communityDetailDialog } from './dialog/community-detail-dialog.html';
// export { printPaymentNoticeDialog } from './dialog/print-payment-notice.html';
export { confirmPasswordDialog } from './dialog/confirm-password.html';
export { bannerPreviewDialog } from './dialog/banner-preview-dialog.html';
// export { gasMeterReadingSettingDialog } from './dialog/gas-meter-reading-setting-dialog.html';
// export { printVerifiedCodeList } from './dialog/print-verified-code-list.html';
// export { addMailDialog } from './dialog/add-mail-dialog.html';
// export { sinyiDataDialog } from './dialog/sinyi-data-dialog.html';
// export { updateCreditDialog } from './dialog/update-credit-dialog.html';
// export { expireCreditDetails } from './dialog/expire-credit-details.html';
// export { creditUpdateLogs } from './dialog/credit-update-logs.html';
export { dialogCheckInAndCheckOutDialog } from './dialog/check-in-and-check-out-dialog.html';
export { progressBarDialog } from './dialog/progress-bar.html';
// export { quitCommunitySelectDialog } from './dialog/quit-community-select-dialog.html';
// export { quitCommunityReasonDialog } from './dialog/quit-community-reason-dialog.html';
// export { postPreviewDialog } from './dialog/post-preview-dialog.html';
// export { userBlockInfoDialog } from './dialog/user-block-info.html';
export { singleTextareaDialog } from './dialog/single-textarea.html';
// export { printGasMeterDialog } from './dialog/print-gas-meter.html';
export { paymentProgressLogDialog } from './dialog/payment-progress-log.html';
// export { adminNotificationsStatusLogDialog } from './dialog/admin-notifications-status-log.html';
export { paymentGenerateDialog } from './dialog/payment-generate-dialog.html';
// export { makeUpBillDialog } from './dialog/make-up-bill-dialog.html';
export { passwordInputDialog } from './dialog/password-input-dialog.html';
// export { newInfoDialog } from './dialog/new-info-dialog.html';
// export { sinyiServicePopover } from './dialog/sinyi-service-popover.html';
// export { committeeMemberListDialog } from './dialog/committee-member-list-dialog.html';
// export { editCommitteeMemberDialog } from './dialog/edit-committee-member-dialog.html';
// export { magnetHookSettingDialog } from './dialog/magnet-hook-setting-dialog.html';
// export { magnetHookScanDialog } from './dialog/magnet-hook-scan-dialog.html';
// export { dmStatusDialog } from './dialog/dm-status-dialog.html';
// export { deviceChoiceDialog } from './dialog/device-choice-dialog.html';
// export { localStoreApplicationDialog } from './dialog/local-store-application-dialog.html';
// export { localStoreJudgeDialog } from './dialog/local-store-judge-dialog.html';
// export { localStoreRejectDialog } from './dialog/local-store-reject-dialog.html';
export { uploadImagesDialog } from './dialog/upload-images.html';
export { reservationDetailDialog } from './dialog/reservation-detail.html';
export { adderssDetailDialog } from './dialog/address-detail.html';
export { customizationItemsDialog } from './dialog/customization-items.html';
export { datePickerDialog } from './dialog/date-picker.html';
export { dragableItemDialogContent } from './dialog/dragable-item-dialog-content.html';
// export { reviewsOfReservation } from './dialog/reviews-of-reservation.html';
export { addressInfoInput } from './dialog/address-info-input.html';
export { uploadQuotationContractFiles } from './dialog/upload-quotation-contract-files.html';
export { serviceTimeLogs } from './dialog/service-time-logs.html';
export { notificationContent } from './dialog/notification-content.html';
export { notificationDetail } from './dialog/notification-detail.html';
export { cancelledWordDialog } from './dialog/cancelled-word-dialog.html';
export { couponUsageDetail } from './dialog/coupon-usage-detail.html';
export { richEditor } from './dialog/rich-editor.html';
export { htmlEditor } from './dialog/html-editor.html';
export { inactiveDurationDialog } from './dialog/inactive-duration-dialog.html';
export { checkboxDialog } from './dialog/checkbox-dialog.html';
export { newebpayDetailsDialog } from './dialog/newebpay-details.html';
export { acceptanceNoteDialog } from './dialog/acceptance-note-dialog.html';
export { valuationSelectionDialog } from './dialog/valuation-selection.html';
export { uploadFilesDIalog } from './dialog/upload-files.html';
export { selfConstructImportLog } from './dialog/self-construct-import-log.html';
export { selfConstructImportErrorLog } from './dialog/self-construct-import-error-log.html';
export { notificationUserNotFound } from './dialog/notification-user-not-found.html';
export { websiteContentDialog } from './dialog/website-content-dialog.html';
export { batchUpdateArticle } from './dialog/batch-update-article.html';
export { batchUpdateAlbum } from './dialog/batch-update-album.html';
export { ywDropzone } from './dialog/yw-dropzone.html';
export { colorPickerDialog } from './dialog/color-picker-dialog.html';
export { referralFeeInfo } from './dialog/referral-fee-info.html';
export { referralFeeUpdateSetting } from './dialog/referral-fee-update-setting.html';
export { reservationColorLabelSetting } from './dialog/reservation-color-label-setting.html';
export { colorSelection } from './dialog/color-selection.html';
export { contactDeadlineDialog } from './dialog/contact-deadline-dialog.html';
export { batchUpdateVender } from './dialog/batch-update-vender.html';
export { unorderedStateAssign } from './dialog/unordered-state-assign.html';
export { unorderedReservationDetail } from './dialog/unordered-reservation-detail.html';
export { manuallyDispatchLogs } from './dialog/manually-dispatch-logs.html';
export { unorderedStateLogs } from './dialog/unordered-state-logs.html';
export { serviceEditDialog } from './dialog/service-edit-dialog.html';
export { unorderedReservationServiceEditLog } from './dialog/unordered-reservation-service-edit-log.html';
export { contactDateEditDialog } from './dialog/contact-date-edit-dialog.html';
export { labelManagementDialog } from './dialog/label-management-dialog.html';
export { compoundSearchTextDialog } from './dialog/compound-search-text-dialog.html';
export { layoutModuleSelectorDialog } from './dialog/layout-module-selector.html';
export { reservationApplyBookedSessionTimeLogDialog } from './dialog/reservation-apply-booked-session-time-logs.html';
export { subcatalogEditorDialog } from './dialog/subcatalog-editor-dialog.html';
export { deductReferralFeeDialog } from './dialog/deduct-referral-fee-dialog.html';
export { moduleItemOrderDialogTmpl } from './dialog/module-item-order-dialog.html';
export { createBillDialogTmpl } from './dialog/create-bill-dialog.html';
export { reissueReferralFeeInvoice } from './dialog/reissue-referralFeeInvoice.html';
export { billDetailDialogTmpl } from './dialog/bill-detail-dialog.html';
export { venderAccountRefundDialogTmpl } from './dialog/vender-account-refund-dialog.html';
export { reservationDetailTmpl } from './page/life/reservation-detail.html';

// life template & dialog
export { lifeDialogTmpl } from './life-template/life-dialog.html';
export { lifeDatePickerDialogTmpl } from './life-template/life-date-picker-dialog.html';
export { lifeReservationSettingDialogTmpl } from './life-template/life-reservation-setting-dialog.html';
export { lifeReservationStatusEditDialogTmpl } from './life-template/life-reservation-status-edit-dialog.html';
// export { lifeReservationSourceDialog } from './life-template/life-reservation-source-dialog.html';
